import React, { Component } from "react"
import Toolbar from '../components/toolbar/Toolbar';
import SideDrawer from '../components/sideDrawer/SideDrawer';
import Backdrop from '../components/backdrop/Backdrop';
import Marquee from "../components/toolbar/Marquee";
import {
  Container,
  Header, Footer,
  Content, Sidebar,
  Row, Col, Grid,
} from 'rsuite'
import { withRouter } from "react-router-dom";
import ErrorBoundary from '../components/common/ErrorBoundary'

class UserLayout extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    console.log(this.state.sideDrawerOpen);
    
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }
    const isMobile = this.state.screenWidth <= 1200;
    localStorage.setItem("isMobile", isMobile);

    return (
      <div id="app-container" style={{ height: '100%' }}>
        <header className="toolbar">
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        </header>
        <div className="container-fluid">
          <div className="container-content">
            <ErrorBoundary>
              {this.props.children}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserLayout)