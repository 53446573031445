import * as TYPE from './types'

const initialState = {
  data_event_checkin_uuid: null,
  data_event_checkin: [],
  data: [],
  dataLive: [],
  dataGlobalSearch: [],
  accounts: [],
  products: [],
  productsTemp: [],
  message: "",
  messageAccount: "",
  status: 500,
  statusAccount: 500,
  messageEventCheckin: "",
  statusEventCheckin: "",
}

function EventReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.EVENT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_LIST_LIVE_ASYNC:
      return {
        ...state,
        dataLive: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_LIST_PRODUCT_ASYNC:
      return {
        ...state,
        products: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_LIST_PRODUCT_TEMP_ASYNC:
      return {
        ...state,
        productsTemp: action.payload.data,
      }
    case TYPE.EVENT_UPDATE_PRODUCT_TEMP_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_UPDATE_DESTROY_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_UPDATE_RESTORE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_LIST_ACCOUNT_ASYNC:
      return {
        ...state,
        accounts: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_UPDATE_ACCOUNT_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.GLOBAL_SEARCH_ASYNC:
      return {
        ...state,
        dataGlobalSearch: action.payload.data,
      }
    case TYPE.EVENT_ACTIVE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.EVENT_CHECKIN_ASYNC:
      return {
        ...state,
        messageEventCheckin: action.payload.message,
        statusEventCheckin: action.payload.status,
      }
    case TYPE.EVENT_GETAWAY_CHECKIN_UUID_ASYNC:
      return {
        ...state,
        data_event_checkin_uuid: action.payload.data,
      }
    case TYPE.EVENT_ROOT_CHECKIN_LIST_ASYNC:
      return {
        ...state,
        data_event_checkin: action.payload.data,
      }
    default:
      return state
  }
}

export default EventReducer