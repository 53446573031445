const mapStateToProps = state => {
  return {
    i18n: state.I18nReducers,
    accounts: state.AccountReducers,
    products: state.ProductReducers,
    productCategories: state.ProductCategoryReducers,
    productStatuses: state.ProductStatusReducers,
    events: state.EventReducers,
    warehouses: state.WarehouseReducers,
  };
};
export default mapStateToProps;