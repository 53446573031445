import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import UserLayout from './layout/UserLayout'
import {
  ConnectedComponent as connect,
} from './constants'

const Main = lazy(() => import('./views'))
const App = lazy(() => import('./views/app'))
const NotFound = lazy(() => import('./views/notfound'));
const Login = lazy(() => import('./views/login'));
const Checkin = lazy(() => import('./views/checkin'));

const LoadingMessage = () => (
  <div className="loading" />
)

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) :
       (
        <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
      )
    }
  />
);

class MainApp extends Component {
  render() {
    let { accounts } = this.props;

    return (
      <Router>
        <Suspense fallback={<LoadingMessage />}>
            <Switch>
              <AuthRoute path="/app" authUser={accounts.token} component={App} />
              <Route path="/login" component={Login} />
              <Route path="/" component={Checkin} />
              {/* <Route path="/" exact component={Main} /> */}
              <Route component={NotFound} />
            </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default connect(MainApp);

// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52/
