export const RefreshAuthorization = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("full_name");
    localStorage.removeItem("email");
    localStorage.removeItem("avatar_img_path");
    localStorage.removeItem("address");
    localStorage.removeItem("permissionName");
    localStorage.removeItem("z");

    // router
    document.location = '/';
}

const getPermission = () => {
    let permission = localStorage.getItem('z');
    if (!permission || permission === null || permission === "") {
        return undefined;
    } else {
        return permission.replace(/\//g, "").split('').reverse().join('');
    }
}

export const setPermission = (id) => {
    if (id == 1) {
        return "/v/n/";
    }
    if (id == 2) {
        return "/k/q/";
    }
    if (id == 3) {
        return "/o/o/r/";
    }
    return "/";
}

export const getPermissionCode = () => {
    let permission = getPermission();

    if (permission === "roo")
        return 3;
    if (permission === "qk")
        return 2;
    if (permission === "nv")
        return 1;
    return 0;
}

export const isPermissionRoot = () => {
    return getPermission() === "roo"
}

export const isPermissionAdmin = () => {
    return getPermission() === "qk"
}

export const isPermissionUser = () => {
    return getPermission() === "nv"
}

export const isPermissionRootOrAdmin = () => {
    let permission = getPermission();
    return permission === "qk" || permission === "roo"
}

import { Url } from '../constants'
export const GetAvatar = path => (Url.END_POINT + "/" + path)

export const GetCurrentDate = (momentDateTime) => {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Ho_Chi_Minh' };

    let curr = new Date().toLocaleDateString('vn-VN', options).split('/');
    if (momentDateTime) {
        curr = new Date(momentDateTime).toLocaleDateString('vn-VN', options).split('/');
    }

    let yyyy = curr[2]
    let mm = curr[0]
    let dd = curr[1]

    if (curr.length <= 1)
        return curr[0];

    return yyyy + "-" + mm + "-" + dd;
}
export const GetCurrentTime = (momentDateTime) => {
    var options = { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Ho_Chi_Minh' };

    let curr = new Date().toLocaleTimeString('vn-VN', options).split(':');
    if (momentDateTime) {
        curr = new Date(momentDateTime).toLocaleTimeString('vn-VN', options).split(':');
    }

    let HH = curr[0]
    let mm = curr[1]

    return HH + ":" + mm;
}

export const isValidateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }
    return (false)
}