import * as TYPE from './types'

// CRUD
const dispatchEventCreateAsync = (data) => ({
  type: TYPE.EVENT_CREATE,
  data,
});
const dispatchEventListAsync = () => ({
  type: TYPE.EVENT_LIST,
});
const dispatchEventUpdateAsync = (data) => ({
  type: TYPE.EVENT_UPDATE,
  data,
});
const dispatchEventDeleteAsync = (data) => ({
  type: TYPE.EVENT_DELETE,
  data,
});
const dispatchEventListLiveAsync = () => ({
  type: TYPE.EVENT_LIST_LIVE,
});

// Products
const dispatchEventListProductAsync = (data) => ({
  type: TYPE.EVENT_LIST_PRODUCT,
  data,
});
const dispatchEventListProductTempAsync = (data) => ({
  type: TYPE.EVENT_LIST_PRODUCT_TEMP,
  data,
});
const dispatchEventUpdateProductAsync = (data) => ({
  type: TYPE.EVENT_UPDATE_PRODUCT_TEMP,
  data,
});

// restore && destroy
// Products
const dispatchEventDestroyAsync = (data) => ({
  type: TYPE.EVENT_UPDATE_DESTROY,
  data,
});
const dispatchEventRestoreAsync = (data) => ({
  type: TYPE.EVENT_UPDATE_RESTORE,
  data,
});

// accounts
const dispatchEventListAccountAsync = (data) => ({
  type: TYPE.EVENT_LIST_ASYNC,
  data,
});
const dispatchEventUpdateAccountAsync = (data) => ({
  type: TYPE.EVENT_UPDATE_ACCOUNT,
  data,
});

// search 
const dispatchGlobalSearchAsync = data => ({
  type: TYPE.GLOBAL_SEARCH,
  data,
})

// active
const dispatchEventActiveAsync = data => ({
  type: TYPE.EVENT_ACTIVE,
  data,
})

// event checkin
const dispatchEventCheckinAsync = data => ({
  type: TYPE.EVENT_CHECKIN,
  data,
})

const dispatchCheckinUUIDAsync = data => ({
  type: TYPE.EVENT_GETAWAY_CHECKIN_UUID,
  data,
})

const dispatchCheckinListAsync = data => ({
  type: TYPE.EVENT_ROOT_CHECKIN_LIST,
  data,
})


export default {
  dispatchEventCreateAsync,
  dispatchEventListAsync,
  dispatchEventUpdateAsync,
  dispatchEventDeleteAsync,
  dispatchEventListLiveAsync,

  dispatchEventListProductAsync,
  dispatchEventListProductTempAsync,
  dispatchEventUpdateProductAsync,

  dispatchEventDestroyAsync,
  dispatchEventRestoreAsync,

  dispatchEventListAccountAsync,
  dispatchEventUpdateAccountAsync,

  dispatchGlobalSearchAsync,

  dispatchEventActiveAsync,

  dispatchEventCheckinAsync,
  dispatchCheckinUUIDAsync,
  dispatchCheckinListAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.EVENT_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.EVENT_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.EVENT_DELETE_ASYNC,
    payload
  }
} 

export const ListProductAsync = (payload) => {
  return {
    type: TYPE.EVENT_LIST_PRODUCT_ASYNC,
    payload
  }
} 
export const ListProductTempAsync = (payload) => {
  return {
    type: TYPE.EVENT_LIST_PRODUCT_TEMP_ASYNC,
    payload
  }
} 
export const UpdateProductAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_PRODUCT_TEMP_ASYNC,
    payload
  }
} 

export const ListLiveAsync = (payload) => {
  return {
    type: TYPE.EVENT_LIST_LIVE_ASYNC,
    payload
  }
} 

export const DestroyAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_DESTROY_ASYNC,
    payload
  }
} 
export const RestoreAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_RESTORE_ASYNC,
    payload
  }
} 

export const UpdateAccountAsync = (payload) => {
  return {
    type: TYPE.EVENT_UPDATE_ACCOUNT_ASYNC,
    payload
  }
} 

export const GlobalSearchAsync = (payload) => {
  return {
    type: TYPE.GLOBAL_SEARCH_ASYNC,
    payload,
  }
}

export const ActiveAsync = (payload) => {
  return {
    type: TYPE.EVENT_ACTIVE_ASYNC,
    payload,
  }
}

export const EventCheckinAsync = (payload) => {
  return {
    type: TYPE.EVENT_CHECKIN_ASYNC,
    payload,
  }
}

export const EventGetawayCheckinUUIDAsync = (payload) => {
  return {
    type: TYPE.EVENT_GETAWAY_CHECKIN_UUID_ASYNC,
    payload,
  }
}

export const EventRootCheckinAsync = (payload) => {
  return {
    type: TYPE.EVENT_ROOT_CHECKIN_LIST_ASYNC,
    payload,
  }
}