import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
// CRUD
function asyncDeletedCreate(data) {
  const { data: {uuid_products} } = data;
  return axios.put(Url.END_POINT + "/api/move_products_in_discarded",
    {
      uuid_products,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDeletedList() {
  return axios.get(Url.END_POINT + "/api/get_all_products_discarded",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDeletedUpdate(data) {
  const { data: {uuid_product} } = data;
  
  return axios.put(Url.END_POINT + "/api/restore_discarded_to_warehouse",
    {
      uuid_product,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDeletedDelete(data) {
  const { data: {uuid_product}} = data;
  return axios.delete(Url.END_POINT + "/api/delete_product_discarded",
    {
      data: {
        uuid_product,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
// LIST
function asyncOutsideList(data) {
  return axios.get(Url.END_POINT + "/api/get_products_out",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncInstockList(data) {
  return axios.get(Url.END_POINT + "/api/get_products",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncOutofdateList(data) {
  return axios.get(Url.END_POINT + "/api/get_products_expiration_date",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUsedList(data) {
  return axios.get(Url.END_POINT + "/api/get_products_used",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncNewList(data) {
  return axios.get(Url.END_POINT + "/api/get_products_in_month",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncPUETList() {
  return axios.get(Url.END_POINT + "/api/get_all_log_product_event",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
// CRUD
function* actionDeletedCreate(data) {
  const response = yield call(asyncDeletedCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeletedCreateAsync(result))
}
function* actionDeletedList() {
  const response = yield call(asyncDeletedList);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Products } } = response.data;
      result.data = Products;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.DeletedListAsync(result))
}
function* actionDeletedUpdate(data) {
  const response = yield call(asyncDeletedUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeletedUpdateAsync(result))
}
function* actionDeletedDelete(data) {
  const response = yield call(asyncDeletedDelete, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeletedDeleteAsync(result))
}
// LIST
function* actionOutsideList(data) {
  const response = yield call(asyncOutsideList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product_out } } = response.data;
      result.data = Product_out;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.OutsideListAsync(result))
}
function* actionInstockList(data) {
  const response = yield call(asyncInstockList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product } } = response.data;
      result.data = Product;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.InstockListAsync(result))
}
function* actionOutofdateList(data) {
  const response = yield call(asyncOutofdateList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product } } = response.data;
      result.data = Product;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.OutofdateListAsync(result))
}
function* actionUsedList(data) {
  const response = yield call(asyncUsedList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product } } = response.data;
      result.data = Product;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.UsedListAsync(result))
}
function* actionNewList(data) {
  const response = yield call(asyncNewList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product } } = response.data;
      result.data = Product;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.NewListAsync(result))
}
function* actionPUETList(data) {
  const response = yield call(asyncPUETList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Products } } = response.data;
      result.data = Products;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.PUETListAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  //CRUD
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_DELETED_CREATE, actionDeletedCreate)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_DELETED_LIST, actionDeletedList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_DELETED_UPDATE, actionDeletedUpdate)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_DELETED_DELETE, actionDeletedDelete)

  // LIST
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_OUTSIDE_LIST, actionOutsideList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_INSTOCK_LIST, actionInstockList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_OUTOFDATE_LIST, actionOutofdateList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_USED_LIST, actionUsedList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_NEW_LIST, actionNewList)
  yield takeLatest(TYPE.WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST, actionPUETList)
}

export default CustomSaga