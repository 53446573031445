import React, { Fragment } from 'react';
import { Panel } from 'rsuite'
import MenuItemComponent from './MenuItemComponent'

const SubMenuItemComponent = ({
  handleToggle,
  isOpen,
  children,
  icon,
  title,
  active,
}) => (
    <Fragment>
      <MenuItemComponent
        isOpen={isOpen}
        title={title}
        icon={icon}
        onClick={handleToggle}
        active={active} />
      <Panel
        collapsible
        defaultExpanded={false}
        expanded={isOpen}
        className="menu-item-collapsible">
        {children}
      </Panel>
    </Fragment>
  );

export default SubMenuItemComponent;