import { combineReducers } from 'redux'
import { reducers as I18nReducers } from './I18n'
import { reducers as AccountReducers } from './Account'
import { reducers as ProductReducers } from './Product'
import { reducers as ProductCategoryReducers } from './ProductCategory'
import { reducers as ProductStatusReducers } from './ProductStatus'
import { reducers as EventReducers } from './Event'
import { reducers as WarehouseReducers } from './Warehouse'


// Functions to catch actions dispatched
export default combineReducers({
    I18nReducers,
    AccountReducers,
    ProductReducers,
    ProductCategoryReducers,
    ProductStatusReducers,
    EventReducers,
    WarehouseReducers,
});