import React from 'react';
import { Redirect, withRouter, Link, NavLink } from 'react-router-dom'
import menuItems from './menu'
import MenuItemComponent from './MenuItemComponent';
import SubMenuItemComponent from "./SubMenuItemComponent";
import { getPermissionCode, GetAvatar } from '../../helpers/Utils'

class sideDrawer extends React.Component {

  state = {
    menuProduct: false,
    menuWarehouse: false,
  };

  onParentItemClicked = (item, menu) => {
    if (menu === "menuProduct") {
      this.setState(state => ({
        [menu]: !state[menu],
        menuWarehouse: false
      }));
    }
    else if (menu === "menuWarehouse") {
      this.setState(state => ({
        [menu]: !state[menu],
        menuProduct: false
      }));
    }
    else {
      this.setState({
        menuProduct: false,
        menuWarehouse: false,
      });
    }
  }

  render() {
    let { location: { pathname } } = this.props;

    let drawerClasses = 'side-drawer';
    if (this.props.show) {
      drawerClasses = 'side-drawer open';
    }

    let permission = getPermissionCode();

    return (
      <React.Fragment>
        <ul className={drawerClasses}>
          <div className="side-drawer-logo-container">
            {/* <span className="side-drawer-logo" /> */}
            <div className="side-drawer-account-container">
              <span className="side-drawer-accounts__avatar">
                <img
                  src={GetAvatar(localStorage.getItem("avatar_img_path"))}
                  alt="image"
                  className="list-thumbnail responsive border-0"
                />
              </span>
              <span className="side-drawer-accounts__name">
                <p>{localStorage.getItem("full_name")}</p>
                <p>{localStorage.getItem("permissionName")}</p>
              </span>
            </div>
          </div>
          {menuItems.map((item, idx) => {
            return (
              <li key={idx} className="custom-nav-item">
                {item.subs
                  ?
                  <SubMenuItemComponent
                    title={item.title}
                    active={pathname === item.to}
                    handleToggle={() => this.onParentItemClicked(item.title, item.subName)}
                    isOpen={this.state[item.subName]}
                    icon={item.icon}>
                    {item.subs.map(sub => {
                      return (
                        permission >= sub.minPermission && <NavLink to={sub.to} key={sub.label}>
                          <MenuItemComponent
                            title={sub.title}
                            icon={"none"}
                            active={pathname === sub.to}
                            isLeaf={sub.isLeaf}
                          />
                        </NavLink>
                      )
                    })}
                  </SubMenuItemComponent>
                  :
                  permission >= item.minPermission && <Link to={item.to}>
                    <MenuItemComponent
                      title={item.title}
                      icon={item.icon}
                      active={pathname === item.to}
                      isLeaf={item.isLeaf}
                      onClick={() => this.onParentItemClicked(item.title, "")}
                    />
                  </Link>
                }
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    )
  }
};

export default withRouter(sideDrawer);
