// CRUD
export const EVENT_CREATE = "EVENT_CREATE"
export const EVENT_CREATE_ASYNC = "EVENT_CREATE_ASYNC"
export const EVENT_UPDATE = "EVENT_UPDATE"
export const EVENT_UPDATE_ASYNC = "EVENT_UPDATE_ASYNC"
export const EVENT_LIST = "EVENT_LIST"
export const EVENT_LIST_ASYNC = "EVENT_LIST_ASYNC"
export const EVENT_DELETE = "EVENT_DELETE"
export const EVENT_DELETE_ASYNC = "EVENT_DELETE_ASYNC"
export const EVENT_LIST_LIVE = "EVENT_LIST_LIVE"
export const EVENT_LIST_LIVE_ASYNC = "EVENT_LIST_LIVE_ASYNC"

// PRODUCTs in EVENT
export const EVENT_LIST_PRODUCT = "EVENT_LIST_PRODUCT"
export const EVENT_LIST_PRODUCT_ASYNC = "EVENT_LIST_PRODUCT_ASYNC"
export const EVENT_LIST_PRODUCT_TEMP = "EVENT_LIST_PRODUCT_TEMP"
export const EVENT_LIST_PRODUCT_TEMP_ASYNC = "EVENT_LIST_PRODUCT_TEMP_ASYNC"
export const EVENT_UPDATE_PRODUCT_TEMP = "EVENT_UPDATE_PRODUCT_TEMP"
export const EVENT_UPDATE_PRODUCT_TEMP_ASYNC = "EVENT_UPDATE_PRODUCT_TEMP_ASYNC"

// DESTROY & RESTORE
export const EVENT_UPDATE_DESTROY = "EVENT_UPDATE_DESTROY"
export const EVENT_UPDATE_DESTROY_ASYNC = "EVENT_UPDATE_DESTROY_ASYNC"
export const EVENT_UPDATE_RESTORE = "EVENT_UPDATE_RESTORE"
export const EVENT_UPDATE_RESTORE_ASYNC = "EVENT_UPDATE_RESTORE_ASYNC"

// ACCOUNT
export const EVENT_LIST_ACCOUNT = "EVENT_LIST_ACCOUNT"
export const EVENT_LIST_ACCOUNT_ASYNC = "EVENT_LIST_ACCOUNT_ASYNC"
export const EVENT_UPDATE_ACCOUNT = "EVENT_UPDATE_ACCOUNT"
export const EVENT_UPDATE_ACCOUNT_ASYNC = "EVENT_UPDATE_ACCOUNT_ASYNC"

// GLOBAL SEARCH
export const GLOBAL_SEARCH = "GLOBAL_SEARCH"
export const GLOBAL_SEARCH_ASYNC = "GLOBAL_SEARCH_ASYNC"

// Active
export const EVENT_ACTIVE = "EVENT_ACTIVE"
export const EVENT_ACTIVE_ASYNC = "EVENT_ACTIVE_ASYNC"


// CHECKIN
export const EVENT_CHECKIN = "EVENT_CHECKIN"
export const EVENT_CHECKIN_ASYNC = "EVENT_CHECKIN_ASYNC"
export const EVENT_GETAWAY_CHECKIN_UUID = "EVENT_GETAWAY_CHECKIN_UUID"
export const EVENT_GETAWAY_CHECKIN_UUID_ASYNC = "EVENT_GETAWAY_CHECKIN_UUID_ASYNC"
export const EVENT_ROOT_CHECKIN_LIST = "EVENT_ROOT_CHECKIN_LIST"
export const EVENT_ROOT_CHECKIN_LIST_ASYNC = "EVENT_ROOT_CHECKIN_LIST_ASYNC"
