import React, { useState, useEffect } from 'react';
import {
  Icon,
} from 'rsuite'
import Profile from './Profile'
import { ConnectedComponent as connect } from '../../constants'

const toolbar = props => {
  const handleOnClickLogout = e => {
    props.dispatchLogoutAsync();
  }

  return (
    <nav className="toolbar__navigation">
      <div className="toolbar_navigation-items">
        <Icon icon="exit" onClick={handleOnClickLogout} />
      </div>
    </nav>
  )
}

export default connect(toolbar)
