import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Row
} from 'rsuite';
import classnames from 'classnames';

function MenuItemComponent(props) {
  const { active, icon, title, isLeaf, isOpen, ...otherProps } = props;

  return (
    <Row className={classnames("menu-item disable-text-selection", {
      "active-container": active
    })} {...otherProps}>
      {active && <div className="active-bar"></div>}
      {<span className={`nav-item-icon-${icon}`} />}
      <div className="sibar-child-wraper">
        {isLeaf && active && <span className="sidebar-dot-wraper">
          <span className="sidebar-dot-icon" />
        </span>}

        <span className={classnames("menu-title", {
          "active-title": active
        })}>{title}</span>

        {!isLeaf && <span className="sidebar-arrow-wraper">
          {!isOpen ? <span className="sidebar-arrow-icon-left" /> : <span className="sidebar-arrow-icon-down" />}
        </span>}
      </div>
    </Row>
  );
}

MenuItemComponent.propTypes = {
  active: bool,
  icon: string,
  title: string
};

export default MenuItemComponent;
